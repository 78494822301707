.input-group {
  //font-awesome has a display of in-block. Needs to be flex to work without flex display input groups
  .fa {
    display: flex;
    padding: 0 1rem;
  }
}

.dv-required:after {
  content: "∗";
  color: $danger-text;
  padding-left: 0.5rem;
}

.form-label{
  @extend .fw-bold;
}

// For .NET MVC/Core apps that use HtmlHelpers or Razor Tag Helpers to build form elements.
// The .NET framework was built with Bootstrap 3 and below, which had this class.
// Bootstrap 4 renamed it to is-invalid, so we clone it.
// https://stackoverflow.com/a/64363767
.input-validation-error {
  @extend .is-invalid;
}

// By default, .invalid-feedback is only visible if it's beside an input.
// There are many instances where that isn't the case (ie: the field is in an input group).
// ASP always adds the ".field-validation-error" class to field error elements, so we can
// use that to ensure visibility
.invalid-feedback.field-validation-error{
  display:block;
}

.validation-summary-valid {
  display: none;
}
.validation-summary-errors ul {
  margin-bottom:0;
}