.select2-selection {
  border-radius: $input-border-radius !important;
  color: $input-color !important;
  border-color: $input-border-color !important;
}
.select2-selection--single {
  padding: 0.3rem 0.25rem !important;
  height: calc(2.25rem + 2px) !important;
  .select2-selection__arrow {
    height: calc(2.25rem + 2px) !important;
  }
}

.select2-selection--multiple {
  line-height: 1.5;
}

.select2-container--open .select2-selection,
.select2-container--focus .select2-selection,
.select2-dropdown {
  border-color: $input-border-color !important;
  border-radius: $input-border-radius !important;
}
.select2-container--focus .select2-selection
/*.select2-search input:focus*/ {
  color: $input-focus-color !important;
  background-color: $input-focus-bg !important;
  border-color: $input-focus-border-color !important;
  outline: 0 !important;
  box-shadow: $input-focus-box-shadow !important;
}
.select2-results__option--highlighted[aria-selected] {
  background-color: $ua-green !important;
}
.select2-search input:focus,
.select2-selection:focus {
  outline: none !important;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: $input-color !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  line-height: 1.4 !important
}

.is-invalid .select2-dropdown,
.select2-container--open.is-invalid .select2-selection,
.select2-container--focus.is-invalid .select2-selection,
.select2-container--default.is-invalid .select2-selection {
  border-color: $danger !important;
}

.input-group .select2-container:not(:first-child) .select2-selection {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.input-group .select2-container:not(:last-child) .select2-selection {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/**
 * For select2s that are required to flex
 */
 .select2-flex + .select2-container {
  flex: 1 1 auto;
  min-width: 10rem;

  & .select2-selection{
    overflow: hidden !important;

    &__rendered{
      white-space: nowrap !important;
      overflow: hidden !important;
      word-break: break-all !important;
      text-overflow: ellipsis !important;
    }
  }
}