/**
 * These styles are only needed until the headers get updated to the new classes.
 * They revert some of the styles in dv-header
 */
.dv-blade > .container > .d-flex{
  width:100%;
}

.dv-menu .navbar .navbar-toggler{
    margin-left:0;
}

#navSearchButton.show #searchIcon:before {
    content: "";
    display: inline-block; min-width: 1.5rem;
}
#navSearchButton.show #vEllipsis {
    display: none;
}

#megaNav {
    box-shadow: 0 10px 9px rgba(0,0,0,.2);
}