// Relative to the "css" directory
$fa-font-path: "../fonts/icons";  

@import '../node_modules/@fortawesome/fontawesome-free/scss/functions';
@import '../node_modules/@fortawesome/fontawesome-free/scss/variables';
@import '../node_modules/@fortawesome/fontawesome-free/scss/mixins';
@import '../node_modules/@fortawesome/fontawesome-free/scss/core';
@import '../node_modules/@fortawesome/fontawesome-free/scss/sizing';
@import '../node_modules/@fortawesome/fontawesome-free/scss/fixed-width';
// @import '../node_modules/@fortawesome/fontawesome-free/scss/list';
@import '../node_modules/@fortawesome/fontawesome-free/scss/bordered-pulled';
@import '../node_modules/@fortawesome/fontawesome-free/scss/animated';
@import '../node_modules/@fortawesome/fontawesome-free/scss/rotated-flipped';
// @import '../node_modules/@fortawesome/fontawesome-free/scss/stacked';
@import '../node_modules/@fortawesome/fontawesome-free/scss/screen-reader';
@import '../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../node_modules/@fortawesome/fontawesome-free/scss/regular';
//@import '../node_modules/@fortawesome/fontawesome-free/scss/brands';


// Fontawesome 6's /scss/brands.scss file outputs all the brand styles (unlike the solid & regular files which just do setup for .fas & .far styles)
// We don't want all the brands so the part of brands.scss that just sets up the .fab style family is included here instead of including the full file
:root, :host {
    --#{$fa-css-prefix}-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
  }
  
  @font-face {
    font-family: 'Font Awesome 6 Brands';
    font-style: normal;
    font-weight: 400;
    font-display: $fa-font-display;
    src: url('#{$fa-font-path}/fa-brands-400.woff2') format('woff2');
  }
  
  .fab,
  .#{$fa-css-prefix}-brands {
    font-family: 'Font Awesome 6 Brands';
    font-weight: 400;
  }


@import "dv-icons-map"; // <- This file is the authoritative list of what icons get included

@each $style, $styleIcons in $icons {
    @each $key, $value in $styleIcons {
        .#{$fa-css-prefix}-#{$key}:before {
            content: fa-content($value);
        }
    }
    
}