@use "sass:map";

.dv-footer{
    background:$ua-green;
    padding: map.get($spacers,3) 0;
    line-height:1;
    text-align:center;
    color:$white;
}
.dv-footer-ua-logo{
    display:inline-block;
    margin:0 0 map.get($spacers,3) 0;
    @include media-breakpoint-up(lg){
        margin-bottom:0;
    }
}
.dv-footer-copyright{
    font-size:0.75rem;
    @include media-breakpoint-up(lg){
        text-align:end;
    }
}