/**
 The default info colour doesn't have enough contrast, so we override it with the text version - which does have enough contrast
 */
.btn-outline-info{
    --bs-btn-color: #{$info-text};
}

/**
Create the btn-table-* buttons
*/
@mixin button-table-variant(
    $parent-hovered-bg,
    $tint-amount: 70%
) {
    $parent-hovered-color: color-contrast($parent-hovered-bg);
    $background: tint-color($parent-hovered-bg, $tint-amount);
    $color: color-contrast($background);
    $hover-background: if($parent-hovered-color == $color-contrast-light, shade-color($parent-hovered-bg, $btn-hover-bg-shade-amount), tint-color($parent-hovered-bg, $btn-hover-bg-tint-amount));
    $hover-color: color-contrast($hover-background);
    $active-background: if($parent-hovered-color == $color-contrast-light, shade-color($parent-hovered-bg, $btn-active-bg-shade-amount), tint-color($parent-hovered-bg, $btn-active-bg-tint-amount));
    $active-color: color-contrast($active-background);

    @include button-variant(
        $background, 
        $background,
        $color,
        $hover-background,
        $hover-background,
        $hover-color,
        $active-background,
        $active-background,
        $active-color,
    );
    
    --#{$prefix}btn-parent-hovered-bg: #{$parent-hovered-bg};
    --#{$prefix}btn-parent-hovered-color: #{$parent-hovered-color};
    //--#{$prefix}btn-bg: #{$background};
    --#{$prefix}btn-border-color: var(--#{$prefix}btn-bg);
}

@each $color, $value in $theme-colors {
    .btn-table-#{$color} {
        @include button-table-variant($value);
    }
}

tr:hover [class*="btn-table"],
.table-button-trigger:hover [class*="btn-table"]{
    --#{$prefix}btn-bg: var(--#{$prefix}btn-parent-hovered-bg);
    --#{$prefix}btn-color: var(--#{$prefix}btn-parent-hovered-color);
}