/**
 * This file contains the styles for the original DV 5.1 navbar.  It can be removed after all the headers get updated
 */
 .dv-menu {
    background: white;
    box-shadow: 0 10px 9px rgba(0,0,0,.2);
  
    .nav-item {
      margin: 0 1rem;
      &:hover .nav-link {
        border-bottom-color: $body-color;
      }
    }
  
    .nav-link {
      color: $body-color !important;
      font-size: 1.125rem;
      font-weight: 500;
      border-bottom: 2px solid transparent;
      padding: 0.5rem 0 0.1rem !important;
    }
    .navbar-toggler {
      align-self: auto;
    }
  
  
    .navbar-nav {
      li {
        border-top: 2px solid transparent;
  
        &:hover {
          border-top: 2px solid white;
        }
  
        &:not(:last-child) {
          margin-right: 0.9375rem;
        }
      }
  
      .dropdown-menu {
        border-radius: 0;
        padding: 0;
        border-width: 0;
        box-shadow: 0 10px 9px rgba(0,0,0,.2);
  
        .dropdown-item {
          padding: 0.9375rem;
  
          &:hover {
            background: $gray-400;
          }
        }
      }
    }
  }