tr.collapse {
  &:hover {
    background-color: inherit;
  }
}

.dv-collapse-label {
  cursor: default;
}

td.dv-table-collapse-cell {
  padding: 0;
}

@media (prefers-reduced-motion: no-preference){
  .dv-collapse-indicator,
  .dv-collapse-carrot{
    transition:transform 150ms;
  }
}
tr[data-bs-toggle="collapse"]:not(.collapsed){
    .dv-collapse-indicator,
    .dv-collapse-carrot {
      transform: rotate(180deg);
  }
}
tbody.dv-sortable-dd{
  cursor:move;
}
