.dv-header {
    display:flex;
    flex-direction:column;
}
.dv-blade {
    background: $ua-green;
    color:white;
    padding:1rem 0;
    > .container, > .container-fluid {
        display:flex;
        align-items:center;
        flex-wrap:wrap;
        justify-content: space-between;
    }
    .dv-ua-logo{
        margin:0;
    }
    .navbar-toggler{
        display:inline-block;
        color:inherit;

        @include media-breakpoint-up(lg){
            display:none;
        }
    }
}
.dv-blade-title{
    flex:1 0 100%;
    order:3;
    padding:1rem 0 0 0;
    @include media-breakpoint-up(lg){
        margin-left:1rem;
        flex:1 0 0;
        padding: $navbar-brand-padding-y 0;
    }
}
.dv-giant-search {
    background-color: $ua-gold;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 0;
    font-size: 1.5rem;
  
    input {
      background-color: $ua-gold;
      color: $body-color;
      font-weight: 200;
      border: 0;
      outline: 0;
      border-radius: 0;
      width: 100%;
      flex-grow:1;
      &:focus {
        border: 0;
        outline: 0;
      }
      &::placeholder {
        color: $body-color;
      }
    }
  }
  .dv-giant-search-btn-inline{
    border:none;
    background:transparent;
    flex-grow:0;
    color:$ua-green;
  }
.dv-blade-buttons{
    width: 3.5rem;
    text-align: center;

    order:2;
    @include media-breakpoint-up(lg){
        order:3;
    }
    .btn{
        color:inherit;
    }

    .nav-search-button{
        .show-shown{
            display:none;
        }
        &.show{
            .show-shown{
                display:inline-block;
            }
            .show-hidden{
                display:none;
            }
        }
    }
}
.mega-nav {
    border-radius:0;
    min-width:100vw!important;
    max-width:100%!important;
    border:none;
    box-shadow: 0 10px 9px rgba(0,0,0,.2);

    .dv-giant-search{
        display:flex;
        align-items:center;
    }
}
.mega-nav-section{
    margin-top:2rem;
}
.dv-quick-link-title{
    text-transform:uppercase;
    font-weight:600;
    margin-bottom:1rem;
}
ul.dv-quick-links {
    line-height: 1rem;
    list-style: none;

    li {
        margin-bottom:0.5rem;
        padding-bottom:0.5rem;
        border-bottom:1px solid $success;
    }

    a {
        color: $body-color;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

@include media-breakpoint-up(lg) {
    ul.dv-quick-links li{
        border-bottom-width:0;
    }
    .mega-nav-section{
        margin-top:0e;
    }
}