/**
 * Make list links actually _look_ like links
 */
a.list-group-item-action,
li.list-group-item-action a {
    color: var(--bs-link-color);
    text-decoration: underline;
}

a.list-group-item-action:not(.active):hover,
li.list-group-item-action:not(.active):hover a {
    color: var(--bs-link-hover-color);
}