.navbar-brand {
  font-size: 1.875rem;
  line-height:1;
  font-weight: 300;
  color: $ua-gold;
  text-decoration: none;
  @include media-breakpoint-down(lg) {
    font-size: 1.5rem;
  }
  &:hover {
    color: $ua-gold;
    text-decoration: none;
  }
}

.dropdown-menu {
  //updating styling for forms in dropdown menus
  form {
    margin: 0;
    padding: 0;

    input[type=submit] {
      cursor: pointer;
    }
  }
}

.dv-header-menu {
  background: white;
  box-shadow: 0 10px 9px rgba(0,0,0,.2);

  .nav-item {
    padding:0.5rem 0;
    @include media-breakpoint-up(lg){
      padding:0.5rem 1rem;
      &:first-child{
        padding-left:0;
      }
      &:last-child{
        padding-right:0;
      }
    }

    &:hover .nav-link {
      border-bottom-color: $body-color;
    }
  }

  .nav-link {
    color: $body-color !important;
    font-size: 1.125rem;
    font-weight: 500;
    border-bottom: 2px solid transparent;
    // Padding override's bootstrap's additional padding 
    // when adding .navbar-expand-lg
    padding-left:0!important;
    padding-right:0!important;
  }
  .navbar-toggler {
    align-self: auto;
  }


  .navbar-nav {
    width:100%;
    flex-direction:row;

    @include media-breakpoint-down(lg){
      flex-direction:column;
    }

    .dropdown-menu {
      border-radius: 0;
      padding: 0;
      border-width: 0;
      box-shadow: 0 10px 9px rgba(0,0,0,.2);

      .dropdown-item {
        padding: 0.9375rem;

        &:hover {
          background: $gray-400;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg){
  .dv-header .hidden-collapse{
    position: absolute;
    top: 80px;
    right: 0;
    width: 100%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    overflow-y: auto;
    visibility: hidden;
    z-index: 10;
    &.show{
      visibility:visible;
    }
  }
}


