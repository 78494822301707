// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

/*
 * -----------------------------------------------------------------------------
 * Diversity variables
 * -----------------------------------------------------------------------------
 */

// fonts
// quotes within quotes because SASS interpolation removes the outer quotes
// when $dv-font is interpolated for $font-family-sans-serif
//$dv-font: "'Roboto Flex'", Calibri;

// All of these widths except xxl are the same as default bootstrap.  xxl has been increased to 1400 to match the main website
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1400px
) !default;


// colours
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$ua-green:        #275D38;
$ua-gold:         #F2CD00;
$ua-dark-green:   #142f1c; // for now just used as link hover colour.

$ua-secondary-dark-yellow:  #FFB600;
$ua-secondary-orange:       #F68D2E;
$ua-secondary-red:          #E56A54;
$ua-secondary-violet:       #C86BA8;
$ua-secondary-medium-green: #007A33;
$ua-secondary-light-green:  #6CC24A;
$ua-secondary-cyan:         #6BBBAE;
$ua-secondary-blue:         #7BA4DB;

$primary:       $ua-green;
$primary-text:  hsl(212, 45%, 49%);
$secondary:     $gray-600;
$success:       $ua-secondary-light-green;
$success-text:  hsl(126.7, 49.7%, 35.1%);
$info:          $ua-secondary-cyan;
$info-text:     hsl(172, 47.6%, 34.1%);
$warning:       $ua-secondary-dark-yellow;
$warning-text:  hsl(34, 100%, 33.5%);
$danger:        $ua-secondary-red;
$danger-text:   hsl(8, 55%, 51.2%);
$light:         $gray-100;
$dark:          $gray-900;
$body-color:    $gray-900;

$theme-colors: (
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "dark":       $dark,
        "ua-green":   $ua-green,
        "ua-gold":    $ua-gold,
        "green":      $ua-green,
        "gold":       $ua-gold
);

// Overriding this with *-text values causes .text-* classes to use the more accessible colours
$utilities-text-colors: map-merge(
        $theme-colors,
        (
                "primary": $primary-text,
                "success": $success-text,
                "info":    $info-text,
                "warning": $warning-text,
                "danger":  $danger-text,
                "black":   $black,
                "white":   $white,
                "body":    $body-color
        )
);

$enable-dark-mode: false;
$enable-transitions: false;

$link-color: $ua-green;
$link-hover-color: $ua-dark-green;

$component-active-bg: $ua-green;

$font-family-sans-serif: "Roboto Flex", Calibri, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$navbar-padding-x: 0;

$toast-background-color: rgba($white, .9) !default;

$progress-bar-bg: $ua-green;

$breadcrumb-font-size: 0.875rem !default;